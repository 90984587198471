<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item style="color: #fff">代驾管理</el-breadcrumb-item>
      <el-breadcrumb-item>司机列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="overflow: auto">

      <!-- 分类搜索 -->
      <!-- <el-row style="margin-top: 15px">
        <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="手机号" style="width:160px"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="3.3">
          <el-select
            size="mini"
            style="width:160px"
            v-model="queryInfo.status"
            clearable
            placeholder="入驻城市"
            @change="getSelectGoodsListStatus"
            class="mr15"
          >
            <el-option
              v-for="(item, index) in commodityStatus"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="3.3">
          <el-select
            size="mini"
            style="width:160px"
            v-model="queryInfo.status"
            clearable
            placeholder="代驾状态"
            @change="getSelectGoodsListStatus"
            class="mr15"
          >
            <el-option
              v-for="(item, index) in commodityStatus"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row> -->
      <!--司机数据展示区域-->
      <el-table
        :data="tableData"
        style="width: 100%"
       
        ref="singleTable"
        highlight-current-row
        border
        row-key="id"
         v-loading="loading"
        stripe
      >
        <el-table-column  align="center" prop="name"  label="名字" width="180"  show-overflow-tooltip></el-table-column>
        <el-table-column  align="center" prop="driveAge" label="驾龄" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column  align="center" prop="number" label="工号" width="180" show-overflow-tooltip ></el-table-column>
        <el-table-column   align="center" prop="score" label="综合评分" width="180" show-overflow-tooltip></el-table-column>
             <el-table-column   align="center" label="接单状态" width="180" show-overflow-tooltip >
          <template slot-scope="scope" >
            <span v-if="scope.row.status==0">手动接单</span>
          <span v-else-if="scope.row.status==1">自动接单</span>
       
          </template>
        </el-table-column>
        <el-table-column   align="center" label="代驾状态" width="180" show-overflow-tooltip >
          <template slot-scope="scope" >
            <span v-if="scope.row.status==-1">禁用中</span>
          <span v-else-if="scope.row.status==0">休息中</span>
          <span v-else-if="scope.row.status==1">空闲中</span>
          <span v-else-if="scope.row.status==2">代驾中</span>
          </template>
        </el-table-column>
           <el-table-column  align="center"  label="审核状态" width="200" show-overflow-tooltip >
        <template slot-scope="scope" >
          <span v-if="scope.row.applyStatus==0">待审核</span>
          <span v-else-if="scope.row.applyStatus==1">审核通过</span>
          <span v-else-if="scope.row.applyStatus==2">审核失败</span>
          </template>
        </el-table-column>
        <el-table-column   label="操作" min-width="200" fixed="right" >
         <template slot-scope="scope" >
          <el-button @click="getdriverdetails(scope.row.id)" size="mini" type="text" >查看</el-button>
          <el-button :disabled="scope.row.applyStatus!==0"  @click="delRoles(scope.row.id)" size="mini" type="text">通过</el-button>
          <el-button  :disabled="scope.row.applyStatus!==0" @click="delRoleso(scope.row.id)" size="mini" type="text">驳回</el-button>
             </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 商品上架区域 -->
    <el-dialog title="代驾信息" :append-to-body="true" :visible.sync="outerVisible">
     
  <el-divider content-position="left"><span style="font-size: 16px;font-weight: 600">司机基础信息</span></el-divider>
       <el-row>
      <div style=" float: left; margin: 10px;"><span>司机名：{{driverdetails.name}}</span></div>
      <div style=" float: left; margin: 10px;"><span>驾龄：{{driverdetails.driveAge}}年</span></div>
      <div style=" float: left; margin: 10px;"  ><span>工号：{{driverdetails.number}}</span></div>
      <div style=" float: left; margin: 10px;"  ><span>综合评分：{{driverdetails.score}}分</span></div>
      <div style=" float: left; margin: 10px;" v-if="driverdetails.status==-1"><span>司机当前状态：禁用中</span></div>
      <div style=" float: left; margin: 10px;" v-else-if="driverdetails.status==0"><span>司机当前状态：休息中</span></div>
      <div style=" float: left; margin: 10px;" v-else-if="driverdetails.status==1"><span>司机当前状态：空闲中</span></div>
      <div style=" float: left; margin: 10px;" v-else-if="driverdetails.status==2"><span>司机当前状态：代驾中</span></div>
      <div style=" float: left; margin: 10px;"  v-if="driverdetails.applyStatus==0"><span>审核状态：待审核 </span></div>
      <div style=" float: left; margin: 10px;" v-else-if="driverdetails.applyStatus==1"><span>审核状态：审核通过</span></div>
       <div style=" float: left; margin: 10px;" v-else><span>审核状态：审核失败</span></div>
           </el-row>
        <el-divider content-position="left"><span style="font-size: 16px;font-weight: 600">司机接单信息</span></el-divider>
           <el-row>
           <div style=" float: left; margin: 10px;" v-if="driverdetails.takeOrderStatus==0"><span>接单设置: 手动接单</span></div>
           <div style=" float: left; margin: 10px;" v-else-if="driverdetails.takeOrderStatus==1"><span>接单设置: 自动接单</span></div>
            <div style=" float: left; margin: 10px;" v-if="driverdetails.takeOrderStatus==1&&driverdetails.autoTakeOrderType==0"><span>自动接单: 按离乘客距离优先</span></div>
            <div style=" float: left; margin: 10px;" v-if="driverdetails.takeOrderStatus==1&&driverdetails.autoTakeOrderType==1"><span>自动接单: 价格优先</span></div>
           </el-row>
 <el-divider content-position="left"><span style="font-size: 16px;font-weight: 600">驾驶证信息</span></el-divider>
 <el-row>
 <div style=" float: left; margin: 10px;"><span>驾驶证类型: {{driverdetails.licenseInfoJson.allowType}}</span></div>
 <div style=" float: left; margin: 10px;"><p style=" float: left;">车牌证: </p><img @click="onimge(driverdetails.licenseInfoJson.licenseUp)" style="margin-left: 12px;width: 200px;height: 100px;" :src="driverdetails.licenseInfoJson.licenseUp" alt=""/></div>
 </el-row>
  <el-divider content-position="left"><span style="font-size: 16px;font-weight: 600">司机身份信息</span></el-divider>
   <el-row>
    <div style=" float: left; margin: 10px;"><span>性别: {{driverdetails.personInfoJson.gender}}性</span></div>
      <div style=" float: left; margin: 10px;"><span>身份证号: {{driverdetails.personInfoJson.idNumber}}</span></div>
     </el-row>
      <el-row>
 <div style=" float: left; margin: 10px;"><p style=" float: left;">手持身份证照:</p><img @click="onimge(driverdetails.personInfoJson.idCardHandled)" style="width: 200px;height: 100px; margin-left: 12px;" :src="driverdetails.personInfoJson.idCardHandled" alt=""/></div>
 <div style=" float: left; margin: 10px;"><p style=" float: left;">身份证照人像:</p><img @click="onimge(driverdetails.personInfoJson.idCardUp)" style="width: 200px;height: 100px; margin-left: 12px;" :src="driverdetails.personInfoJson.idCardUp" alt=""/></div>
 <div style=" float: left; margin: 10px;"><p style=" float: left;">身份证照国徽:</p><img @click="onimge(driverdetails.personInfoJson.idCardDown)" style="width: 200px;height: 100px; margin-left: 12px;" :src="driverdetails.personInfoJson.idCardDown" alt=""/></div>
 
 </el-row>

    </el-dialog>
    <el-dialog :visible.sync="dialogimg"  :append-to-body="true" >
              <img width="100%" :src="dialogimgsrc" alt="" />
            </el-dialog>
  </div>
</template>

<script>
// 引入拖动列表组件

import dataFunction from "@/utils/dataFunction";
import {getUserSignInDetailList,searchDriverInfoById,handleDriverJoinRequest } from "../../api/substitute";

export default {
  name: "UsesignList",
  data() {
    return {
      // 司机详情信息
      driverdetails:{
        licenseInfoJson:{},
        personInfoJson:{}
      },
      dialogimgsrc:"",
      dialogimg:false,
      // 弹窗页面设置
      setType: 0,
       loading: false,
      // 新建区域弹窗
      outerVisible: false,
  
      // 查找商品列表参数对象
      queryInfo: {
        page: 1,
        size: 10,
        cityCode:"",
        order:"desc",
        keyword:""
      },
      time: "",
adddatafo:{

},
      total: 0,
        // 司机列表
      tableData: [],
    };
  },
  created() {
    this.getGoodsList();
  },
  methods: {
    // 审核
     // 通过审核
    async delRoleso(id) {
      const confirmResult = await this.$confirm(
        `此操作将会拒绝通过，是否继续？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm")
        return this.$message.info(`已取消审核操作`);
      const formData = new FormData()
        formData.append("id", id);
       formData.append("status",2)
      const { data: res } = await this.$http.post(`/api/driver/handleDriverJoinRequest`,formData)
      if (res.code !== 200) return this.$message.error("审核失败请稍后重试");
             
      this.$message.success("已拒绝通过");

      await this.getGoodsList();
    },
     // 通过审核
    async delRoles(id) {
      const confirmResult = await this.$confirm(
        `此操作将会通过审核，是否继续？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm")
        return this.$message.info(`已取消审核操作`);
      const formData = new FormData()
        formData.append("id", id);
       formData.append("status",1)
      const { data: res } = await this.$http.post(`/api/driver/handleDriverJoinRequest`,formData)
      if (res.code !== 200) return this.$message.error("审核失败请稍后重试");
             
      this.$message.success("已通过审核");

      await this.getGoodsList();
    },
    // 点击图片展示图片
    onimge(e){
      console.log(e);
this.dialogimgsrc=e
      this.dialogimg=true
    },
    // 查看司机详情
   async getdriverdetails(e){
   const { data: res } = await searchDriverInfoById({id:e})
   this.driverdetails=res.body
   this.driverdetails.licenseInfoJson=JSON.parse(res.body.licenseInfoJson)
  this.driverdetails.personInfoJson=JSON.parse(res.body.personInfoJson)
  this.outerVisible=true
    },
    /*
     * 页面加载请求所有的司机数据
     * 默认请求第一页 十条数据
     * */
    async getGoodsList() {

        const { data: res } = await getUserSignInDetailList(this.queryInfo);
        if (res.code !== 200) return this.$message.error("司机列表获取错误");
        this.tableData = res.body.list;
        this.total = res.body.total ;

  


    },

    handleCurrentChange(data) {
      this.queryInfo.page = data;
      this.getGoodsList();
    },
    // 省份城市搜索
    handleSizeChange(newSize) {
      this.queryInfo.size = newSize;
      this.getGoodsList();
    },
    // 日期搜索
    dateHandle() {
      this.queryInfo.pageNum = 1;
      this.queryInfo.time = dataFunction(this.time);
      this.getGoodsList();
    },
    //选择省
    onprovince(data) {
      this.queryInfo.pageNum = 1;
      this.queryInfo.province = data.value;
      this.getGoodsList();
    },
    // 选择市
    oncity(data) {
      this.queryInfo.pageNum = 1;
      this.queryInfo.city = data.value;
      this.getGoodsList();
    },

    // 退出
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
      window.sessionStorage.removeItem("activePath");
    },
  },
};
</script>

<style scoped lang="scss">
.mr15 {
  margin-right: 18px;
}

p {
  margin: 0;
  padding: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

.content ul {
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-top: 25px;

    span {
      font-weight: bold;
      display: block;
      width: 120px;
    }

    img {
      display: block;

      width: 100px;
    }
  }
}


.v-distpicker ::v-deep select {
  height: 35px !important;

  padding-top: 0.4rem;
  font-size: 14px;
}
.CheckInTimeTitle {
  font-size: 12px;
  color: #606266;
  margin-right: 5px;

}
.Price {
  display: flex;
  flex-wrap: wrap;
}

.resetInputSize {
  width: 100px;
}

.quill-editor {
  line-height: normal;
}

.goodDesc p img {
  width: 100px;
}
.el-buttonr {
  width: 70px;
  height: 25px;
}
.goods-details {
  .el-row {
    margin-bottom: 10px;
  }

  .goodDesc {
    p {
      img {
        width: 100px !important;
      }
    }
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
</style>